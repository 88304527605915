<template>
  <div
    class="account-button"
    :class="{ 'has-account-button': accountSettings.url }"
  >
    <a
      v-if="isLoggedIn && accountSettings.url"
      :href="accountSettings.url"
      :target="accountSettings.target ? '_blank' : '_self'"
      class="button--solid"
    >
      <i class="icon fal fa-user-circle" aria-hidden="true"></i>
      {{ accountText }}
    </a>

    <a v-if="isLoggedIn" class="button--logout" @click.prevent="logout">
      {{ "Logout" | t }}
    </a>

    <a v-else class="button--solid" @click.prevent="login">
      <i class="icon fal fa-user-circle" aria-hidden="true"></i>
      {{ "Login" | t }}
    </a>
  </div>
</template>

<script>
import EventBus from "./../event-bus";
import user from "../services/userServices";
import { LOCAL_STORAGE_REDIRECT_URL } from "../constants/localStorage";

export default {
  name: "AccountButton",
  props: {
    loginHref: {
      type: String,
      default: null,
    },
    accountSettings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  computed: {
    accountText() {
      if (this.accountSettings.text) return this.accountSettings.text;
      return this.$options.filters.t("My account");
    },
  },
  async mounted() {
    this.isLoggedIn = await user.isAuthenticated();
  },
  methods: {
    async login() {
      if (this.loginHref) {
        localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, location.pathname);
        location.href = this.loginHref;
      } else {
        await user.doLogin({ returnTo: location.pathname });
      }
    },
    async logout() {
      await user.doLogout();
      this.isLoggedIn = false;
      EventBus.$emit("loggedIn", false);
    },
  },
};
</script>
