<template>
  <div>
    <button v-if="cjpBudget" class="button--primary" @click="openPopup">
      <i class="icon fas fa-shopping-basket"></i>{{ buttonText }}
    </button>
    <a
      v-if="!cjpBudget"
      id="scoor-deal-checkout-tablet"
      class="button--primary"
      :target="external ? '_blank' : '_self'"
      @click="openLink"
    >
      <i class="icon fas fa-shopping-basket"></i>
      {{ buttonText }}
    </a>
  </div>
</template>

<script>
import user from "../services/userServices/userService";
import EventBus from "./../event-bus";

export default {
  name: "OrderNowButton",
  props: {
    cjpBudget: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkoutUrl: {
      type: String,
      required: false,
      default: "",
    },
    buttonText: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeMembershipRequired: {
      type: String,
      required: false,
      default: "false",
    },
  },
  data() {
    return {
      isLoggedIn: false,
      isActiveMembershipRequired: false,
    };
  },
  async mounted() {
    if (this.activeMembershipRequired === "true") {
      this.isActiveMembershipRequired = true;
    }
    EventBus.$on("loggedIn", (event) => {
      if (event === false) {
        this.isLoggedIn = false;
      }
    });

    try {
      this.isLoggedIn = await user.isAuthenticated();
      this.hasActiveMembership = await user.hasActiveMembership();
      if (this.hasActiveMembership === "false") {
        this.hasActiveMembership = false;
      }
    } catch (e) {
      this.isLoggedIn = false;
    }
  },
  methods: {
    openPopup() {
      if (
        this.isLoggedIn &&
        ((this.hasActiveMembership && this.isActiveMembershipRequired) ||
          !this.isActiveMembershipRequired)
      ) {
        return EventBus.$emit("openPopupSpendBudget", false);
      } else if (this.isLoggedIn === false) {
        window.location.href = "/login";
      } else {
        EventBus.$emit("openPopupActiveMembershipRequired", false);
        return false;
      }
    },
    openLink() {
      if (
        this.isLoggedIn &&
        ((this.hasActiveMembership && this.isActiveMembershipRequired) ||
          !this.isActiveMembershipRequired)
      ) {
        if (this.external) {
          return window.open(this.checkoutUrl, "_blank");
        } else {
          window.location.href = this.checkoutUrl;
        }
      } else if (this.isLoggedIn === false) {
        window.location.href = "/login";
        return false;
      } else {
        EventBus.$emit("openPopupActiveMembershipRequired", false);
        return false;
      }
    },
  },
};
</script>
